
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography, Empty, Pagination, Button } from 'antd';
import JobCard from './JobCard';
import { generateCSV } from '../../utils/helpres';


const JOBS_PER_PAGE = 25


const JobList = ({ currentTab, setModalJob, setFetching, setShowModal }) => {
    const [jobs, setJobs] = useState([])
    const [page, setPage] = useState(1);
    const [totalJobs, setTotalJobs] = useState(0)


    const tabJobs = useMemo(() => {
        return (
            jobs.filter((job) => job.status === currentTab.replace(' ', '')) || []
        );
    }, [jobs, currentTab]);

    const fetchJobs = useCallback((pages) => {
        setFetching(true);
        axios
            .get('/admins/categories', {
                params: {
                    category: currentTab,
                    perPage: JOBS_PER_PAGE,
                    page: pages || page
                }
            })
            .then(({ data }) => {
                setJobs(data.jobs)
                data.totalJobs && setTotalJobs(data.totalJobs)
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setFetching(false);
            });
    }, [currentTab, page]);

    const downloadJobsCSV = () => {
        axios.get(`/admins/jobs-download/${currentTab}`)
            .then(({ data }) => {
                const output = generateCSV(data);
                const blob = new Blob([output], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', `${currentTab}JobsHistory.csv`);
                a.click();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setTotalJobs(0)
        setPage(1)
        setJobs([])
        fetchJobs();
    }, [currentTab])


    return (
        <div className='jobs-list'>
            <Button className='download' type='primary' onClick={downloadJobsCSV}>Download jobs</Button>
            {tabJobs.map((job) => (
                <JobCard key={job._id} job={job} setModalJob={setModalJob} setShowModal={setShowModal} />
            ))}
            {tabJobs.length > 0 &&
                <Pagination
                    current={page}
                    total={totalJobs}
                    pageSize={JOBS_PER_PAGE}
                    onChange={newPage => {
                        setPage(newPage);
                        fetchJobs(newPage);
                    }}
                />
            }
            {!tabJobs.length && (
                <Empty
                    description={
                        <Typography.Text type="secondary">
                            {`${currentTab.replace('_', ' ')} list is empty`}
                        </Typography.Text>
                    }
                />
            )}
        </div>
    )
}

export default JobList