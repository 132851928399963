import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { generateCSV } from '../../utils/helpres'
import { Button, Card, Typography } from 'antd';
const { Title, Text } = Typography;

const Reviews = () => {
    const [averageRating, setAverageRating] = useState(0)
    const [reviewsCount, setReviewsCount] = useState(0)

    const getReviewsInfo = () => {
        axios.get('/admins/reviews-info')
            .then(({ data }) => {
                setAverageRating(data.averageRating)
                setReviewsCount(data.totalReviews)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getReviewsInfo()
    }, [])

    const downloadReviewsCSV = () => {
        axios.get('/admins/reviews-download')
            .then(({ data }) => {
                const output = generateCSV(data);
                const blob = new Blob([output], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', 'reviewHistory.csv');
                a.click();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className='reviews'>
            <Card className='card'>
                <Title level={3}>Reviews Statistics</Title>
                <Text type="secondary">Average rating: {averageRating}</Text>
                <br />
                <Text type="secondary">Reviews count: {reviewsCount}</Text>
            </Card>
            <Button type="primary" onClick={downloadReviewsCSV}>Download Reviews</Button>
        </div>
    )
}

export default Reviews