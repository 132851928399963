import { Tabs, Typography, } from 'antd';
import React, { useState } from 'react'
import UsersList from './UsersList';



const Users = () => {

    const [tabs, setTabs] = useState([
        { value: 'provider', name: 'Providers' },
        { value: 'customer', name: 'Customers' },
    ]);
    const [currentTab, setCurrentTab] = useState(tabs[0].value);

    return (
        <div>
            <Tabs
                activeKey={currentTab}
                onChange={setCurrentTab}

                items={
                    tabs.map(tab => ({
                        label: tab.name,
                        key: tab.value,
                        children: (
                            <UsersList currentTab={currentTab} />
                        )
                    }))
                }>
            </Tabs>
        </div>
    )
}

export default Users