import { Link, Navigate, Outlet, Route, useNavigate } from "react-router-dom";
import { useStore } from "../store";
import { Breadcrumb, Layout, Menu, Button } from 'antd';
import { LaptopOutlined, UserOutlined, StarOutlined } from '@ant-design/icons';
import { useEffect } from "react";
import logo from '../assets/logo.svg';

const ProtectedRoute = () => {
    const store = useStore();
    const { isAuth } = store.auth;

    useEffect(() => {
        if (isAuth) {
            store.auth.fetchUserInfo();
        }
    }, [isAuth, store.auth]);

    const navigate = useNavigate();

    const items = [
        {
            key: '/jobs',
            label: <Link to='/jobs'>Jobs</Link>,
            icon: <LaptopOutlined />,
        },
        {
            key: '/users',
            label: <Link to='/users'>Users</Link>,
            icon: <UserOutlined />,
        },
        {
            key: '/reviews',
            label: <Link to='/reviews'>Reviews</Link>,
            icon: <StarOutlined />,
        }
    ]

    return (
        isAuth ?
            <Layout className="layout">
                <Layout.Header className="header">
                    <div className="header-content">
                        <img src={logo} alt="Admin Panel Logo" />
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                store.auth.unauthorize();
                                navigate('/auth')
                            }}
                        >
                            Logout
                        </Button>
                    </div>
                </Layout.Header>
                <Layout>
                    <Layout.Sider width={200} className="site-layout-background">
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            className="menu"
                            items={items}
                        >
                        </Menu>
                    </Layout.Sider>
                    <Layout.Content className="layout-content">
                        <Breadcrumb className="breadcrumb" />
                        <div className="site-layout-content">
                            <Outlet />
                        </div>
                    </Layout.Content>
                </Layout>
            </Layout>
            :
            <Navigate to='/auth' />

    )
}

export default ProtectedRoute