
import './global.less';
import { Link, Route, Routes, Navigate, } from 'react-router-dom';
import Jobs from './pages/Jobs/Jobs';

import Auth from './pages/Auth/Auth';
import React from 'react';

import Users from './pages/Users/Users';
import JobDetails from './pages/JobDetails/JobDetails';
import ProtectedRoute from './components/ProtectedRoute';
import User from './pages/User/User';
import Reviews from './pages/Reviews/Reviews';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route element={<ProtectedRoute />}>
          <Route
            path="/"
            element={<Navigate to={"/jobs"} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobs/:id" element={<JobDetails />} />
          <Route path="/users" element={<Users />} />
          <Route path={"/profile/:type/:id"} element={<User />} />
          <Route path="/reviews" element={<Reviews />} />
        </Route>
      </Routes>
    </div>
  );
}


export default App;
