import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { Card, Typography, Tag, Space } from 'antd';

const { Title, Text, Link } = Typography;

const JobDetails = () => {
    const { id: jobId } = useParams();
    const [job, setJob] = useState({});
    const [fetching, setFetching] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        setJob({});
        setFetching(true);
        axios
            .get(`/jobs/${jobId}`)
            .then(({ data }) => {
                setJob(data);
            })
            .catch((e) => {
                navigate('/');
            })
            .finally(() => {
                setFetching(false);
            });
    }, [jobId, navigate]);

    useEffect(() => {
        axios
            .get(`/jobs/${jobId}/provider`)
            .then(({ data }) => {
                setJob((prev) => ({ ...prev, provider: data }));
            }).catch((e) => {
                navigate('/');
            })
    }, [jobId]);


    return (
        <div className="job-details">
            <Space direction="vertical" className="space">
                <Card>
                    <Title level={2}>{job.title}</Title>

                </Card>

                <Card>
                    <Title level={4}>Description</Title>
                    <Text className="text">{job.description}</Text>

                    <Title level={4}>Status</Title>
                    <Text className="text">{job.status}</Text>



                    <Title level={4}>Tags</Title>
                    {job.tags?.map((tag) => (
                        <Tag className="text" color='#108ee9' key={tag}>{tag}</Tag>
                    ))}

                    <Title level={4}>Customer</Title>

                    <Text>
                        <Link href={`/profile/customer/${job.creator?._id}`}>
                            {job.creator?.name} {job.creator?.surname}
                        </Link>
                    </Text>

                    <Title level={4}>Provider</Title>

                    <Space>
                        <Link href={`/profile/provider/${job.provider?._id}`}>
                            {job.provider?.name} {job.provider?.surname}
                        </Link>
                        <Text>{job.provider?.city}</Text>
                    </Space>

                    <Title level={4}>Date and time</Title>

                    <Space direction="vertical">
                        <Text className="text">{moment(job.date).format('MMMM Do YYYY')}</Text>
                        <Text className="text">{job.time?.startTime} - {job.time?.endTime}</Text>
                    </Space>

                    <Title level={4}>Price</Title>
                    <Text className="text">${job.price}</Text>
                </Card>
            </Space>
        </div>
    )
}

export default JobDetails