import axios from 'axios';
import { makeAutoObservable } from 'mobx';


class Auth {
    isAuth = false;
    token = null;

    user = {
        id: null,
        userType: null,
    };

    constructor() {
        makeAutoObservable(this);
        const savedToken = localStorage.getItem('token');
        const userType = localStorage.getItem('userType');
        if (savedToken) {
            this.authorize(savedToken, userType);
        }
    }

    *fetchUserInfo() {
        try {
            const { data } = yield axios.get('/auth/user');

            this.user = {
                id: data.id,
                userType: data.type
            };

        } catch (e) {
            if (e.response?.status === 401) {
                this.unauthorize();
            }
        }
    }

    authorize(token, userType) {

        this.token = token;
        this.user.userType = userType;
        this.isAuth = true;
        localStorage.setItem('token', token);
        localStorage.setItem('userType', userType);
        axios.defaults.headers.authorization = `Bearer ${token}`;
    }

    unauthorize() {
        this.isAuth = false;
        this.token = null;
        this.user.userType = null;
        localStorage.removeItem('userType');
        localStorage.removeItem('token');
    }
}

const auth = new Auth();
export default auth;