import axios from "axios";
import React, { useCallback, useState } from "react";
import { useStore } from "../../store";
import { Input, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

const Auth = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    message: "",
  });

  const store = useStore();
  const navigate = useNavigate();

  const signIn = useCallback(() => {
    axios
      .post(`/admin/signin`, { login, password })
      .then(({ data }) => {
        store.auth.authorize(data.token, data.type);
        navigate("/jobs");
      })
      .catch((e) => {
        setError({ message: e.response.data.error });
      });
  }, [login, password, store.auth]);

  return (
    <div className="auth">
      <Typography.Title level={1}>Auth</Typography.Title>
      {error && <Text type="danger">{error.message}</Text>}

      <Input
        type="text"
        value={login}
        onChange={(e) => setLogin(e.target.value)}
        placeholder="Login"
        className="input"
      />

      <Input.Password
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        className="input"
      />
      <Button type="primary" onClick={signIn}>
        Sign In
      </Button>
    </div>
  );
};

export default Auth;
