import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Card, Empty, Typography, Input, Pagination, Button } from 'antd';
import { Link } from 'react-router-dom';
import { generateCSV } from '../../utils/helpres';


const UsersList = ({ currentTab }) => {
    const [page, setPage] = useState(1)
    const [users, setUsers] = useState([])
    const [totalUsers, setTotalUsers] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [pageSize, setPageSize] = useState(10);


    const fetchUsers = ({ searchValues, newPage, pageSize, } = {}) => {
        setSearchValue(searchValues)
        setPage(newPage)
        pageSize && setPageSize(pageSize)
        axios.get('/admins/users', {
            params: {
                type: currentTab,
                perPage: pageSize,
                page: newPage || page,
                search: searchValues
            }
        })
            .then(({ data }) => {
                setUsers(data.users);
                data.totalUsers && setTotalUsers(data.totalUsers)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleSearch = (search) => {
        setSearchValue(search.target.value)
    }

    const getUsersCSV = () => {
        axios.get(`/admins/users-download/${currentTab}`)
            .then(({ data }) => {
                const output = generateCSV(data);
                const blob = new Blob([output], { currentTab: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', `${currentTab}s-data .csv`);
                a.click();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setTotalUsers(0)
        setPage(1)
        setUsers([])
        fetchUsers({ pageSize: 10 });
    }, [currentTab])



    return (
        <div className='users-list'>
            <Button className='download' type='primary' onClick={getUsersCSV}>Download users CSV</Button>
            <Input.Search
                placeholder="Type users first name, last name, or email"
                onSearch={(e) => fetchUsers({ searchValues: e, newPage: 1, pageSize: pageSize })}
                onChange={(e) => handleSearch(e)}
                value={searchValue}
                enterButton
                allowClear
            />
            {users.map((user, index) => (
                <div className='card-container' key={index}>
                    <Card
                        className='card'
                        bordered={false}
                        hoverable
                    >
                        <div className='card-content'>
                            <div>
                                <Link to={`/profile/${currentTab}/${user._id}`}>
                                    <Typography.Title level={4}>{user.name} {user.surname}</Typography.Title>
                                </Link>
                                <Typography.Text type="secondary">{user.email}</Typography.Text>
                                <Typography.Text type="secondary">{user.phone}</Typography.Text>

                            </div>
                            <div>
                                <Typography.Text
                                    className='text'
                                    style={{ backgroundColor: user.isKicked ? '#ff4d4f' : '#1677ff' }}
                                >
                                    {`User is ${user.isKicked ? "kicked" : "not kicked"}`}
                                </Typography.Text>
                            </div>
                        </div>
                    </Card>
                </div>
            ))}
            {users.length > 0 &&
                <Pagination
                    current={page}
                    total={totalUsers}
                    showSizeChanger
                    pageSize={pageSize}
                    onChange={(newPage, e) => {
                        setPage(newPage);
                        fetchUsers({ newPage, searchValues: searchValue, pageSize: e });
                    }}
                />
            }
            {!users.length && (
                <Empty
                    description={
                        <Typography.Text type="secondary">
                            {`${currentTab.replace('_', ' ')} list is empty`}
                        </Typography.Text>
                    }
                />
            )}
        </div>
    )
}

export default UsersList