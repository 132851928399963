import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';


axios.defaults.timeout = 10000;
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


