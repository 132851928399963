import React from 'react'
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom'


const JobCard = ({ job, setModalJob, setShowModal }) => {
    return (
        <Card
            key={job._id}
            title={<Link to={`/jobs/${job._id}`}>{job.title}</Link>}
            className='job-card'
        >
            <div>
                <p><strong>Status:</strong> {job.status}</p>
                {job.bids[0]?.provider ?
                    <p><strong>Provider:</strong> {job.bids[0].provider.name} {job.bids[0].provider.surname}</p>
                    :
                    <p>Provider not found</p>
                }

                {job.creator ?
                    <p><strong>Customer:</strong> {job.creator.name} {job.creator.surname}</p>
                    :
                    <p>Customer not found</p>
                }
            </div>
            <div className="buttons" >
                {(job.status === "dispute") &&
                    <>
                        <Button
                            type="primary"
                            className='button-primary'
                            onClick={() => {
                                setModalJob(job);
                                setShowModal('completed');
                            }}
                        >
                            Pay to the provider
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                setModalJob(job);
                                setShowModal('refund');
                            }}
                        >
                            Refund the Client
                        </Button>
                    </>
                }
            </div>
        </Card>
    )
}

export default JobCard