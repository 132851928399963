export const generateCSV = function (dataArray) {
    if (!dataArray || !dataArray.length) return;

    const headers = Object.keys(dataArray[0]);
    const csvRows = [headers.join(',')];

    dataArray.forEach((data) => {
        const values = headers.map(header => data[header]);
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
};