import AuthStore from './auth';
import axios from 'axios';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = "http://localhost:8080/api";

export const RootStore = {
    auth: AuthStore,
};

export const useStore = () => RootStore;
