import axios from 'axios';
import React, { useCallback, useState } from 'react'
import { Tabs, Button, Modal, Spin, message, } from 'antd';
import JobList from './JobList';
import { useNavigate } from 'react-router-dom';


const DisputeJobs = () => {


    const [tabs, setTabs] = useState([
        { value: 'dispute', name: 'Dispute' },
        { value: 'active', name: 'Active' },
        { value: 'working', name: 'Working' },
        { value: 'completed', name: 'Completed' },
        { value: 'pending', name: 'Pending' },
        { value: 'archive', name: 'Archive' },
        { value: 'canceled', name: 'Canceled' },
        { value: 'pending_acceptance', name: 'Pending Acceptance' },
        { value: 'late_canceled', name: 'Late Canceled' },
    ]);


    const [currentTab, setCurrentTab] = useState(tabs[0].value);
    const [fetching, setFetching] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalJob, setModalJob] = useState(null);

    const navigate = useNavigate();

    const sendCompleteJob = useCallback(() => {
        axios
            .patch(`/admins/${modalJob._id}/complete`).then(() => {
                setShowModal(false);
                message.success('Job completed successfully');
                navigate('/admin/jobs')
            })
            .catch((error) => {
                message.error('Failed to complete the job');
            });
    }, [modalJob])

    const sendRefundJob = useCallback(() => {
        axios
            .post(`/admins/${modalJob._id}/refund`).then(() => {
                setShowModal(false);
                message.success('Job refunded successfully')
                navigate('/admin/jobs')
            })
            .catch((error) => {
                message.error('Failed to refund the job');
            });
    }, [modalJob])

    return (
        <div className="jobs">

            {fetching &&
                <div className='spin'>
                    <Spin />
                </div>
            }


            <Modal
                title="Complete this job?"
                open={showModal === 'completed'}
                onCancel={() => setShowModal(false)}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={sendCompleteJob}>
                        Yes, complete
                    </Button>,
                ]}
            >
                <p>{modalJob?.title}</p>
            </Modal>

            <Modal
                title="Refund this job?"
                open={showModal === 'refund'}
                onCancel={() => setShowModal(false)}
                centered
                footer={[
                    <Button key="submit" type="primary" danger onClick={sendRefundJob}>
                        Yes, refund
                    </Button>,
                ]}
            >
                <p>{modalJob?.title}</p>
            </Modal>

            <Tabs
                activeKey={currentTab}
                onChange={setCurrentTab}
                className='tabs'
                items={
                    tabs.map(tab => ({
                        label: tab.name,
                        key: tab.value,
                        children: (
                            <>
                                <JobList currentTab={currentTab} setModalJob={setModalJob} setFetching={setFetching} setShowModal={setShowModal} />
                            </>
                        )
                    }))
                }
            />
        </div>
    )
}

export default DisputeJobs